import { DependencyList, useCallback } from 'react';
import isNil from 'ramda/src/isNil';

const useFocusOnMount = (dependencies: DependencyList = []) =>
  useCallback((el: HTMLElement | null) => {
    if (!isNil(el)) {
      el.focus();
    }
  }, dependencies);

export default useFocusOnMount;
