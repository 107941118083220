import React, { ChangeEvent, useCallback } from 'react';
import { Input } from 'mangools-react-components/src';
import classnames from 'classnames';
import { isNil } from 'ramda';

interface TextField {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    renderIcon?(): React.ReactNode;
    placeholder: string;
    required?: boolean;
    value: string;
    inputRef?: React.RefObject<HTMLInputElement> | null;
    label?: string;
    inputContainerClassnames?: string;
    inputClassnames?: string;
    labelClassnames?: string;
    textFieldWrapperClassnames?: string;
    error: string | null;
    debounceMs?: number;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const TextField: React.FC<TextField> = ({
    onChange,
    placeholder = '',
    renderIcon,
    required = false,
    value,
    inputRef = { current: null },
    label,
    inputContainerClassnames = '',
    inputClassnames = '',
    labelClassnames = '',
    textFieldWrapperClassnames = 'mg-margin-b-30',
    error = '',
    debounceMs = 0,
    onBlur = () => null,
}: TextField) => {
    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    }, []);

    const hasError = !isNil(error);

    return (
        <div className={classnames(textFieldWrapperClassnames)}>
            {label && (
                <label
                    htmlFor={label}
                    className={classnames('font-montserrat font-14 color-grey mg-margin-b-5', labelClassnames)}
                >
                    {label}
                </label>
            )}
            <div className={classnames('mg-input-container', inputContainerClassnames)}>
                <Input
                    className={classnames('mg-input font-14', { 'is-error': hasError }, inputClassnames)}
                    onChange={handleChange}
                    placeholder={placeholder}
                    required={required}
                    spellCheck={false}
                    type="text"
                    value={value}
                    inputRef={inputRef}
                    debounceMs={debounceMs}
                    id={label}
                    onBlur={onBlur}
                />
                {renderIcon && renderIcon()}
            </div>
            {hasError && (
                <div className="mg-alert is-validation is-error mg-margin-b-15 fade-in-left animated-once">{error}</div>
            )}
        </div>
    );
};
