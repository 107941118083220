import findIndex from 'ramda/src/findIndex';
import propEq from 'ramda/src/propEq';
class CollectionService {
  /**
   * @param  {collection: Array.<Object>} collection of data
   * @param  {selectedItemId <string>} selectedItemId
   * @param  {searchKey: string} searchKey [id]
   * @return {tuple: Array} [item, index] tuple made of item and item index in collection
   */
  static getNextItemAndIndexTuple(collection, selectedItemId, searchKey = 'id') {
    const selectedItemIdIndex = findIndex(propEq(searchKey, selectedItemId))(collection);
    if (selectedItemIdIndex + 1 < collection.length) {
      const newIndex = selectedItemIdIndex + 1;
      return [collection[newIndex], newIndex];
    } else if (selectedItemIdIndex === collection.length - 1) {
      return [collection[collection.length - 1], selectedItemIdIndex];
    }
    return [collection[0], 0];
  }

  /**
   * @param  {collection: Array.<Object>} collection of data
   * @param  {selectedItemId <string>} selectedItemId
   * @param  {searchKey: string} searchKey [id]
   * @return {tuple: Array} [item, index] tuple made of item and item index in collection
   */
  static getPreviousItemAndIndexTuple(collection, selectedItemId, searchKey = 'id') {
    const selectedItemIdIndex = findIndex(propEq(searchKey, selectedItemId))(collection);
    if (selectedItemIdIndex > 0) {
      const newIndex = selectedItemIdIndex - 1;
      return [collection[newIndex], newIndex];
    }
    return [collection[0], 0];
  }
}
export default CollectionService;