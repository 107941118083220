import { LinkRefSources } from './LinkRefSources';
import Urls from './Urls';
const APP_URLS_MAP = {
  [LinkRefSources.KWFINDER_APP]: Urls.KWFINDER_APP_URL,
  [LinkRefSources.SERPCHECKER_APP]: Urls.SERPCHECKER_APP_URL,
  [LinkRefSources.SERPWATCHER_APP]: Urls.SERPWATCHER_APP_URL,
  [LinkRefSources.LINKMINER_APP]: Urls.LINKMINER_APP_URL,
  [LinkRefSources.SITEPROFILER_APP]: Urls.SITEPROFILER_APP_URL,
  [LinkRefSources.MANGOOLS_APP]: Urls.MANGOOLS_APP_URL,
  [LinkRefSources.CONTENTGENERATOR_APP]: Urls.CONTENTGENERATOR_APP_URL
};
const getAppUrl = app => APP_URLS_MAP[app];
export default getAppUrl;