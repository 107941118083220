import * as React from 'react';
import isNil from 'ramda/src/isNil';
import DOMService from 'mangools-commons/lib/services/DOMService';

type ImageProps = {
  src?: string;
  darkSrc?: string;
  lightSrc?: string;
  className?: string;
  alt?: string;
  width?: string;
  height?: string;
  colorScheme?: string;
};

const getSrc = (
  src?: string,
  darkSrc?: string,
  lightSrc?: string,
  colorScheme?: string,
): string | undefined => {
  if (!isNil(colorScheme)) {
    const isDarkMode = DOMService.isDarkMode(colorScheme);

    if (isDarkMode && !isNil(darkSrc)) {
      return darkSrc;
    } else if (!isDarkMode && !isNil(lightSrc)) {
      return lightSrc;
    }
  }

  return src;
};

const Image = ({ darkSrc, lightSrc, colorScheme, ...props }: ImageProps) => {
  const defaultSrc = props.src || lightSrc || darkSrc;

  if (!defaultSrc) {
    return null;
  }

  return (
    <img
      {...props}
      src={getSrc(defaultSrc, darkSrc, lightSrc, colorScheme)}
      alt={''}
    />
  );
};

export default Image;
