export const LinkRefSources = {
  KWFINDER_APP: 'app-kw',
  SITEPROFILER_APP: 'app-sp',
  SERPCHECKER_APP: 'app-sch',
  LINKMINER_APP: 'app-lm',
  SERPWATCHER_APP: 'app-sw',
  MANGOOLS_APP: 'app-mg',
  CONTENTGENERATOR_APP: 'app-cg'
};
export const LinkRefTypes = {
  USER_DROPDOWN: 'user-dropdown'
};