import React, { useEffect } from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { withVisibilityLogic } from 'mangools-react-components/src';
import { useDispatch, useSelector } from 'react-redux';

import { MessageListWrapper } from 'components/other/MessageListWrapper';
import MessageHolder from 'components/messages/MessageHolder';
import { ImportKwfListMessageList } from 'components/messages/ImportKwfListMessage/ImportKwfListMessageList';

import { ImportKwfListTargets } from 'constants/ImportKwfListTargets';

import kwImage from 'images/kwfinder-icon.svg';

import ListType from 'types/ListType';

const ImportKwfListMessage = props => {
    const dispatch = useDispatch();

    const {
        resetSelectedListIdsActionCreator,
        importActionCreator,
        selectedListIdsSelector,
        listSelectActionCreator,
    } = ImportKwfListTargets[props.importTarget];

    const selectedListIds = useSelector(state => selectedListIdsSelector(state));

    useEffect(() => {
        props.requestLists();
    }, []);

    function handleResetSelectedLists(...args) {
        dispatch(resetSelectedListIdsActionCreator(...args));
    }

    useEffect(() => {
        return () => {
            handleResetSelectedLists();
        };
    }, []);

    function handleListSelect(...args) {
        dispatch(listSelectActionCreator(...args));
    }

    function handleImportListsClick(...args) {
        dispatch(importActionCreator(...args));
        props.onClose();
    }

    return (
        <MessageHolder onClose={props.onClose}>
            <div>
                <div className="mg-modal-header">
                    <img src={kwImage} style={{ height: '48px' }} alt="SERPWatcher logo" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">Import from KWFinder</h3>
                    <MessageListWrapper>
                        <ImportKwfListMessageList
                            lists={props.lists}
                            fetching={props.listsFetching}
                            onImportListsClick={handleImportListsClick}
                            onListSelect={handleListSelect}
                            selectedListIds={selectedListIds}
                            onResetSelectedLists={handleResetSelectedLists}
                        />
                    </MessageListWrapper>
                </div>
            </div>
        </MessageHolder>
    );
};

ImportKwfListMessage.propTypes = {
    lists: arrayOf(ListType).isRequired,
    listsFetching: bool.isRequired,
    onClose: func.isRequired,
    requestLists: func.isRequired,
    importTarget: string.isRequired,
};

const enhanced = withVisibilityLogic(ImportKwfListMessage);

export { enhanced as ImportKwfListMessage };
