import React from 'react';
import { useSelector } from 'react-redux';

import { LinkRefSources } from 'mangools-commons/lib/constants/LinkRefSources';
import { MangoolsNavBar } from 'mangools-react-components/src';
import DOMService from 'mangools-commons/lib/services/DOMService';

import { currentColorSchemeSelector } from 'selectors/uiSelectors';
import { isReportRouteSelector } from 'selectors/commonSelectors';
import mnglsImageWhite from 'images/mangools-logo-full-white.svg';
import Preloader from 'components/other/Preloader';
import Strings from 'constants/Strings';
import DarkModeToggle from 'components/other/DarkModeToggle';
import MessageContainer from 'components/messages/MessageContainer';

import config from 'appConfig';

function FullAppPreloader() {
    const colorScheme = useSelector(currentColorSchemeSelector);
    const isReportRoute = useSelector(isReportRouteSelector);

    return (
        <>
            {isReportRoute === false ? (
                <header className="mg-header">
                    <MangoolsNavBar
                        appName={LinkRefSources.SERPWATCHER_APP}
                        rightEl={(
                            <div className="uk-flex uk-flex-middle mg-margin-r-30" style={{ opacity: 0.2 }}>
                                <div className="uk-flex uk-flex-middle">
                                    {DOMService.isMobile() && (
                                        <ul className="mg-nav uk-visible-small">
                                            <li>
                                                <DarkModeToggle />
                                            </li>
                                        </ul>
                                    )}
                                    <div className="mg-preloader-block mg-margin-l-15" style={{ width: '30px' }} />
                                </div>
                            </div>
                        )}
                        onShowAppPanel={() => null}
                        logoDarkSrc={mnglsImageWhite}
                        logoLightSrc={mnglsImageWhite}
                        env={config.APP_ENV}
                        mangoolsApiHost={config.MANGOOLS_API_HOST}
                    />
                    <div className="mg-header-app uk-flex uk-flex-middle uk-flex-space-between uk-hidden-small">
                        <div className="uk-flex" style={{ opacity: 0.2 }}>
                            <div className="mg-padding-0-15 uk-visible-large">
                                <div className="mg-preloader-block" style={{ width: '60px' }} />
                            </div>
                            <div className="mg-padding-0-15 uk-visible-large">
                                <div className="mg-preloader-block" style={{ width: '60px' }} />
                            </div>
                            <div className="mg-padding-0-15 uk-visible-large">
                                <div className="mg-preloader-block" style={{ width: '60px' }} />
                            </div>
                        </div>
                        <div className="uk-flex uk-visible-large" style={{ opacity: 0.2 }}>
                            <div className="mg-padding-0-15">
                                <div className="mg-preloader-block" style={{ width: '20px' }} />
                            </div>
                            <div className="mg-padding-0-15">
                                <div className="mg-preloader-block" style={{ width: '20px' }} />
                            </div>
                            <div className="mg-padding-0-15">
                                <div className="mg-preloader-block" style={{ width: '30px' }} />
                            </div>
                        </div>
                    </div>
                </header>
            ) : null}
            <div className="uk-position-cover uk-flex uk-flex-middle uk-flex-center">
                <Preloader colorScheme={colorScheme} text={Strings.components.common.loading} />
                <MessageContainer />
            </div>
        </>
    );
}

export default FullAppPreloader;
