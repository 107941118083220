import * as React from 'react';

import Urls from 'mangools-commons/lib/constants/Urls';
import UserPlanTypes from 'mangools-commons/lib/constants/UserPlanTypes';

type UserPlanCta = {
  appName: string;
  userPlanType?: string;
};

const UserPlanCta = ({ userPlanType, appName }: UserPlanCta) => {
  if (
    userPlanType === UserPlanTypes.FREE ||
    userPlanType === UserPlanTypes.TRIAL
  ) {
    return (
      <div className="uk-visible-large font-12">
        <a
          className="mg-btn is-white is-xsmall"
          href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?ref=header-${appName}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Upgrade now
        </a>
      </div>
    );
  }

  return null;
};

export default UserPlanCta;
