import React, { useRef } from 'react';
import classnames from 'classnames';
import { isNil } from 'ramda';
import UrlService from 'mangools-commons/lib/services/UrlService';

import { Input } from 'mangools-react-components/src';

import { ProtocolDropdown } from 'components/other/ProtocolDropdown';
import { HTTP, HTTPS } from 'constants/Protocols';

import { CustomizeReportFormActionType } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelContainer';

interface UrlProtcolInputProps {
    error: string | null;
    website: string;
    label: string;
    placeholder: string;
    protocol: typeof HTTP;
    onDispatch: (action: CustomizeReportFormActionType) => void;
    setCustomizeReportPanelDirty: () => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const UrlProtocolInput: React.FC<UrlProtcolInputProps> = (props: UrlProtcolInputProps) => {
    const inputRef = useRef(null);
    const handleUrlChange = (e: any) => {
        if (e.target.value.startsWith('http://')) {
            props.onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'protocol', value: HTTP } });
        } else if (e.target.value.startsWith('https://')) {
            props.onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'protocol', value: HTTPS } });
        }

        props.setCustomizeReportPanelDirty();
        props.onDispatch({
            type: 'SET_FIELD_VALUE',
            payload: { field: 'website', value: UrlService.getWithoutProtocol(e.target.value) },
        });
    };

    const handleProtocolChange = (newProtocol: typeof HTTP) => {
        props.setCustomizeReportPanelDirty();
        props.onDispatch({ type: 'SET_FIELD_VALUE', payload: { field: 'protocol', value: newProtocol } });
    };

    return (
        <div className="mg-margin-b-30">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="font-montserrat font-14 color-grey mg-margin-b-5">{props.label}</label>
            <div className="mg-input-container is-url is-small is-button">
                <ProtocolDropdown onChange={handleProtocolChange} value={props.protocol} />
                <Input
                    className={classnames('mg-input is-small font-14', { 'is-error': !isNil(props.error) })}
                    onChange={handleUrlChange}
                    onBlur={props.onBlur}
                    placeholder={props.placeholder}
                    required={false}
                    spellCheck={false}
                    type="text"
                    value={props.website}
                    inputRef={inputRef}
                />
                {!isNil(props.error) && (
                    <div className="mg-alert is-validation is-error mg-margin-b-15 fade-in-left animated-once">
                        {props.error}
                    </div>
                )}
            </div>
        </div>
    );
};
