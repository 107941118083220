import * as React from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import ShortcutService from 'mangools-commons/lib/services/ShortcutService';
import CollectionService from 'mangools-commons/lib/services/CollectionService';
import { isNil } from 'ramda';

type ListNavigationProps = {
  itemId?: string;
  onArrowDownShortcut: (item: any, event: KeyboardEvent) => void;
  onArrowUpShortcut: (item: any, event: KeyboardEvent) => void;
  onSearchShortcut: (event: KeyboardEvent) => void;
  isEnterShortcutDisabled?: boolean;
  data: Array<Record<string, any>>;
  selectedItemId: string;
  onEnterShortcut: () => void;
  shortcuts: Record<string, any>;
  children: React.ReactNode;
  arrowDownLabel: string;
  arrowUpLabel: string;
  enterLabel: string;
  searchLabel: string;
  shortcutKeys: Array<string>;
};

function ListNavigation({
  isEnterShortcutDisabled = false,
  itemId = 'id',
  ...props
}: ListNavigationProps) {
  const [ARROW_DOWN, ARROW_UP, ENTER, SEARCH] = props.shortcutKeys;

  const handleArrowDown = (e: KeyboardEvent) => {
    const item = CollectionService.getNextItemAndIndexTuple(
      props.data,
      props.selectedItemId,
      itemId,
    );
    props.onArrowDownShortcut(item, e);
  };

  const handleArrowUp = (e: KeyboardEvent) => {
    const item = CollectionService.getPreviousItemAndIndexTuple(
      props.data,
      props.selectedItemId,
      itemId,
    );
    props.onArrowUpShortcut(item, e);
  };

  const handleSearchShortcut = (e: KeyboardEvent) => {
    e.preventDefault();
    props.onSearchShortcut(e);
  };

  const handleEnterShortcut = (e: KeyboardEvent) => {
    e.preventDefault();
    props.onEnterShortcut();
  };

  const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
    handlers: {
      [ARROW_DOWN]: { func: handleArrowDown },
      [ARROW_UP]: { func: handleArrowUp },
      [ENTER]: {
        func: handleEnterShortcut,
        isDisabledCb: isEnterShortcutDisabled,
      },
      ...(!isNil(props.onSearchShortcut) &&
        !isNil(SEARCH) && { [SEARCH]: { func: handleSearchShortcut } }),
    },
    areShortcutsDisabled: false,
    shortcuts: props.shortcuts,
  });

  return (
    <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers}>
      <>{props.children}</>
    </GlobalHotKeys>
  );
}

export default React.memo(ListNavigation);
