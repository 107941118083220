import { useEffect } from 'react';
import isNil from 'ramda/src/isNil';

/* 
    TODO
    add type to ref
 */

const useOnEscapeCallback = (ref: any, handler: any, disabled = false) => {
  const listener = (event: KeyboardEvent): void => {
    if (!isNil(ref.current) && event.key === 'Escape') {
      handler();
    }
  };

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    }

    return;
  }, [ref]);
};

export default useOnEscapeCallback;
