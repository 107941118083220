import * as React from 'react';
import Urls from 'mangools-commons/lib/constants/Urls';
import UserPlanTypes from 'mangools-commons/lib/constants/UserPlanTypes';
import UsercomService from 'mangools-commons/lib/services/UsercomService';
import { Image } from 'mangools-react-components/src';
import Emoji from 'components/other/Emoji';
import { userEventNames } from 'constants/usercom';
import { analyticsEvents } from 'constants/analytics';
import { gtmTrack } from 'actions/analyticsActions';
import { useDispatch, useSelector } from 'react-redux';
import { newTrackingErrorNewTextSelector } from 'selectors/dataSelectors';
import { ErrorsText } from '../../constants/OutOfLimitExceptionErrors';

type PricingMessageProps = {
    userPlanType: string;
    colorScheme: string;
    juiceImage: string;
    mangoolsLogoImage: string;
    mangoolsLogoWhiteImage: string;
    queryParams: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const PricingMessageContent = (props: PricingMessageProps): React.ReactElement => {
    const dispatch = useDispatch();
    const handleNeedMoreKws = () => {
        UsercomService.createEvent({ eventName: userEventNames.SW_NEED_MORE_KEYWORDS });
        dispatch(gtmTrack({ event: analyticsEvents.NEED_MORE_KEYWORDS }));
    };

    const errorNewText = useSelector(newTrackingErrorNewTextSelector);

    const renderUpgradePrompt = () => {
        if (props.userPlanType === UserPlanTypes.FREE) {
            return (
                <div>
                    <p className="mg-margin-b-30">
                        You will need to upgrade your Mangools plan, if you want to continue using our SEO tools.
                    </p>
                    <p>
                        <a
                            className="mg-btn is-green"
                            href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?${props.queryParams}`}
                            rel="noopener"
                            target="_blank"
                        >
                            <strong>Upgrade my plan</strong>
                        </a>
                    </p>
                    <p className="font-14 color-grey">Stay cool, we have a 48h money back guarantee!</p>
                </div>
            );
        } else if (errorNewText === ErrorsText.OUT_OF_TRACKINGS) {
            return (
                <div>
                    <p className="mg-margin-b-30">
                        You&apos;ve reached the limit of tracked domains or keywords.
                        <br />
                        To add a new domain or keyword, please upgrade your plan.
                    </p>
                    <p>
                        <a
                            className="mg-btn is-green"
                            href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?${props.queryParams}`}
                            rel="noopener"
                            target="_blank"
                            onClick={props.onClick}
                        >
                            <strong>Upgrade my plan</strong>
                        </a>
                    </p>
                    <p className="font-14 color-grey">Stay cool, we have a 48h money back guarantee!</p>
                </div>
            );
        } else {
            return (
                <div>
                    <p className="mg-margin-b-30">
                        You’ve used all of your tracked keywords.
                        <br />
                        Please upgrade your plan or purchase additional tracked keywords package.
                    </p>
                    <p>
                        <a
                            className="mg-btn is-green"
                            href={`${Urls.MANGOOLS_PLANS_AND_PRICING_URL}?${props.queryParams}`}
                            rel="noopener"
                            target="_blank"
                            onClick={props.onClick}
                        >
                            <strong>Upgrade my plan</strong>
                        </a>
                    </p>
                    <p>
                        <button type="button" className="mg-btn is-white" onClick={handleNeedMoreKws}>
                            <strong>Add keywords package</strong>
                        </button>
                    </p>
                    <p className="font-14 color-grey">Stay cool, we have a 48h money back guarantee!</p>
                </div>
            );
        }
    };

    const renderHeader = () => {
        if (props.userPlanType === UserPlanTypes.FREE) {
            return (
                <h3 className="font-24 uk-text-bold">
                    Your plan has expired
                    <span className="mg-margin-l-5">
                        <Emoji symbol="😢" label="crying-face" />
                    </span>
                </h3>
            );
        } else {
            return (
                <h3 className="font-24 uk-text-bold">
                    You&apos;re doing too much
                    <span className="mg-margin-l-5">
                        <Emoji symbol="😊" label="smiling-face-with-smiling-eyes" />
                    </span>
                </h3>
            );
        }
    };

    return (
        <>
            <div className="mg-modal-header is-mngls bg-fff">
                <img src={props.juiceImage} alt="Upgrade my plan" width="110" />
            </div>
            <div className="mg-modal-content bg-fff">
                {renderHeader()}
                {renderUpgradePrompt()}
                <Image
                    className="mg-margin-b-5 mg-margin-t-15"
                    lightSrc={props.mangoolsLogoImage}
                    darkSrc={props.mangoolsLogoWhiteImage}
                    colorScheme={props.colorScheme}
                    alt="Mangools logo"
                    width="140"
                />
                <div className="font-14">Juicy SEO tools you will love</div>
            </div>
        </>
    );
};

export default PricingMessageContent;
