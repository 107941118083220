import FileSaver from 'file-saver';
import isNil from 'ramda/src/isNil';
import { reject } from 'ramda';
const DEFAULT_FILENAME = 'mangools_export';
const CSV_MIME = 'data:text/csv;charset=utf-8';

/**
 * Service for in-browser download triggering.
 */
class DownloaderService {
  /**
   * Triggers download of csvData via blob.
   *
   * @param {csvData: string} CSV data string.
   * @return {error: bool, payload: object}
   */
  static downloadCSV(filename = DEFAULT_FILENAME, csvData) {
    return new Promise((resolve, _reject) => {
      if (!new Blob()) {
        resolve(false);
      } else {
        const name = `${filename}.csv`;
        const blob = new Blob([csvData], {
          type: CSV_MIME
        });
        FileSaver.saveAs(blob, name);
        resolve(true);
      }
    });
  }
  static downloadBase64Image({
    base64Data,
    fileName = DEFAULT_FILENAME,
    fileFormat = 'png'
  }) {
    return new Promise(resolve => {
      if (isNil(base64Data)) {
        reject();
      } else {
        const byteString = atob(base64Data);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], {
          type: 'image/png'
        });
        FileSaver.saveAs(blob, `${fileName}.${fileFormat}`);
        resolve(true);
      }
    });
  }
}
export default DownloaderService;