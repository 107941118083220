export default {
  // MANGOOLS COLOR
  MAIN_COLOR: '#FFBE02',
  MAIN_COLOR_DARKMODE: '#77601b',
  ORANGE_SATURATED: '#EF7A24',
  SECONDARY_COLOR_MNGLS: '#FD5F69',
  // TEXT COLORS
  TEXT_DARK: '#302F33',
  TEXT_DARK_NEW: '#3E4348',
  TEXT_GREY: '#758088',
  TEXT_GREY_NEW: '#7C7D80',
  // BACKGROUNDS
  BACKGROUND_LIGHTBLUE: '#ECF5F9',
  BACKGROUND_LIGHTBLUE_NEW: '#E0E3FE',
  BACKGROUND_LIGHTGREY: '#EFF2F5',
  BACKGROUND_LIGHTGREY_DAKMODE: '#161718',
  BACKGROUND_LIGHTORANGE: '#FCFCF9',
  BACKGROUND_LIGHTORANGE_NEW: '#FFF9EC',
  DARK_BG: '#3B4146',
  DARK_BG_NEW: '#302F33',
  DARK_BG_LIGHTER: '#49474e',
  DARK_BG_LIGHT: '#212023',
  WHITE3: '#F2F2F2',
  WHITE: '#FFF',
  // SOLID COLORS
  BLUE: '#2DA6E9',
  BLUE_NEW: '#0521CA',
  BLUE_DARKMODE: '#9ca8f2',
  BLUE_BTN_DARKMODE: '#1e3a5f',
  GREEN: '#67BD2E',
  GREEN_NEW: '#1BA005',
  GREEN_DARKMODE: '#286a1e',
  FRESH_GREEN: '#AADA2B',
  FRESH_GREEN_DARKMODE: '#5e7324',
  YELLOW: '#F5DA24',
  YELLOW_DARKMODE: '#77601b',
  ORANGE: '#F99D36',
  ORANGE_DARKMODE: '#79441e',
  RED: '#BD462E',
  RED_DARKMODE: '#6d271b',
  RED_BTN_DARKMODE: '#6d271b',
  GREY_DARK_LINE: '#302f33',
  GREY_DARK_LINE_DARKMODE: '#dadada',
  GREY_LIGHT_LINE: '#dadada',
  GREY_LIGHT_LINE_DARKMODE: '#8d8e92',
  // BORDERS
  BORDER_COLOR: '#E7E7E7',
  BORDER_COLOR_NEW: '#EFF2F5',
  BORDER_INPUT: '#ACACAD',
  // APPS
  KWFINDER: '#E1432C',
  SERPCHECKER: '#E0CE2D',
  SERPWATCHER: '#6E22C6',
  LINKMINER: '#3D2DE0',
  SITEPROFILER: '#B71072'
};