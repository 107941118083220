export const Types = {
  CSV: 'text/csv',
  PLAIN_TEXT: 'text/plain'
};
export const CSVTypes = ['application/csv', 'application/vnd.ms-excel', 'application/x-csv', 'data:text/csv', 'text/comma-separated-values', 'text/tab-separated-values', 'text/x-comma-separated-values', 'text/x-csv', Types.CSV];
export const SupportedTypes = CSVTypes.concat([Types.PLAIN_TEXT]);
export const Extensions = {
  CSV: 'csv',
  TXT: 'txt'
};

/* eslint-disable max-len */
export const ACCEPT_STRING = `${SupportedTypes.join(',')},.${Extensions.TXT},.${Extensions.CSV}`;
/* eslint-enable max-len */