import isNil from 'ramda/src/isNil';
import Breakpoints from '../constants/Breakpoints';
import ColorSchemes from '../constants/ColorSchemes';

/**
 * DOMService class, operates on DOM API
 */
class DOMService {
  static isElementInViewport(el) {
    if (!isNil(el)) {
      const rect = el.getBoundingClientRect();
      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    } else {
      return false;
    }
  }
  static getInnerWidth() {
    return window && window.innerWidth;
  }
  static isMobile(width = null) {
    if (isNil(width)) {
      return window.innerWidth <= Breakpoints.SMALL;
    } else {
      return width <= Breakpoints.SMALL;
    }
  }
  static isTablet(width = null) {
    if (isNil(width)) {
      return window.innerWidth > Breakpoints.SMALL && window.innerWidth <= Breakpoints.MEDIUM;
    } else {
      return width > Breakpoints.SMALL && width <= Breakpoints.MEDIUM;
    }
  }
  static isDesktop(width = null) {
    if (isNil(width)) {
      return window.innerWidth > Breakpoints.MEDIUM && window.innerWidth <= Breakpoints.LARGE;
    } else {
      return width > Breakpoints.MEDIUM && width <= Breakpoints.LARGE;
    }
  }
  static isLargeDesktop(width = null) {
    if (isNil(width)) {
      return window.innerWidth >= Breakpoints.FULL_HD;
    } else {
      return width >= Breakpoints.FULL_HD;
    }
  }
  static isDarkMode(colorScheme = null) {
    if (!isNil(colorScheme)) {
      if (colorScheme === ColorSchemes.DARK) {
        return true;
      } else if (colorScheme === ColorSchemes.LIGHT) {
        return false;
      } else if (colorScheme === ColorSchemes.AUTO) {
        if (DOMService.hasMatchMedia()) {
          return window.matchMedia('(prefers-color-scheme: dark)').matches;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (DOMService.hasMatchMedia()) {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
      return false;
    }
  }
  static darkModeOn() {
    document.querySelector('body').classList.add('dark-mode');
  }
  static darkModeOff() {
    document.querySelector('body').classList.remove('dark-mode');
  }
  static registerMqlEventListener(cb) {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    if (DOMService.hasMatchMedia()) {
      mql.addListener(() => {
        cb(DOMService.isDarkMode() ? ColorSchemes.DARK : ColorSchemes.LIGHT);
      });
    }
    return mql;
  }
  static hasMatchMedia() {
    return !isNil(window.matchMedia);
  }
  static removeMqlEventListener(mql) {
    mql.removeMqlEventListener('change');
  }
  static isTextSelected() {
    return window.getSelection().type === 'Range';
  }
}
export default DOMService;