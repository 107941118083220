import Environments from 'mangools-commons/lib/constants/Environments';
import { getMangoolsUrls } from 'mangools-commons/lib/constants/getMangoolsUrls';

export const API_VERSION = 'v3';

const CONFIG = {
    APPS_ROOT_HOST: process.env.APPS_ROOT_HOST,
    API_HOST: process.env.API_HOST,
    API_PATH: `/${API_VERSION}/serpwatcher`,
    APP_ENV: Environments.PRODUCTION,
    APP_HOST: `${process.env.APPS_ROOT_HOST}/serpwatcher`,
    APP_VERSION: process.env.GIT_VERSION, // eslint-disable-line no-undef
    KWFINDER_API_PATH: `/${API_VERSION}/kwfinder`,
    LOCAL_STORAGE_VERSION: 1, // NOTE: Increase when LS reset is needed
    MANGOOLS_API_HOST: process.env.MANGOOLS_APP_HOST,
    ROLLBAR_API_KEY: 'aaa44319e3a14992b408818d16f22fb7',
    SERPCHECKER_API_PATH: `/${API_VERSION}/serpchecker`,
    MANGOOLS_URLS: getMangoolsUrls(Environments.PRODUCTION),
    development() {
        return CONFIG.APP_ENV === Environments.DEVELOPMENT;
    },
    production() {
        return CONFIG.APP_ENV === Environments.PRODUCTION;
    },
    staging() {
        return CONFIG.APP_ENV === Environments.STAGING;
    },
};

export default CONFIG;
