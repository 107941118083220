import React from 'react';
import { bool, func, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Image } from 'mangools-react-components/src';

import { currentColorSchemeSelector } from 'selectors/uiSelectors';

import MessageHolder from 'components/messages/MessageHolder';
import Strings from 'constants/Strings';
import sadImg from 'images/sad.svg';
import sadImgDark from 'images/sad-dark.svg';

function FailureMessage(props) {
    const colorScheme = useSelector(state => currentColorSchemeSelector(state));

    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-error">
                    <Image lightSrc={sadImg} darkSrc={sadImgDark} colorScheme={colorScheme} alt="Oh no" width="100" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold">{props.header}</h3>
                    {props.details ? <p className="ln-12">{props.details}</p> : null}
                    <p>{Strings.messages.failure.footer_sorry}</p>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}

FailureMessage.propTypes = {
    details: string,
    header: string,
    onClose: func.isRequired,
    visible: bool.isRequired,
};

FailureMessage.defaultProps = {
    details: null,
    header: 'Sorry, something went wrong',
};

export default FailureMessage;
