const UserPlanTypes = {
  AGENCY: 'AGENCY',
  BASIC: 'BASIC',
  CUSTOM: 'CUSTOM',
  FREE: 'FREE',
  NO_PLAN: 'NO_PLAN',
  PREMIUM: 'PREMIUM',
  TRIAL: 'TRIAL'
};
export const isPaidPlan = plan => plan === UserPlanTypes.TRIAL || plan === UserPlanTypes.BASIC || plan === UserPlanTypes.PREMIUM || plan === UserPlanTypes.AGENCY || plan === UserPlanTypes.CUSTOM;
export default UserPlanTypes;