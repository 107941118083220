import * as React from 'react';
import { ElementType } from 'react';

type withVisibilityLogicProps = {
  visible: boolean;
};

const withVisibilityLogic =
  (Component: ElementType) => (props: withVisibilityLogicProps) => {
    const { visible, ...rest } = props;
    if (visible) {
      return <Component {...rest} />;
    }

    return null;
  };

export default withVisibilityLogic;
