import includes from 'ramda/src/includes';
import { Extensions, SupportedTypes, Types } from '../constants/FileConstants';

/**
 * File service class.
 */
class FileService {
  /**
   * Returns true if provided file is CSV or Plain text file.
   *
   * @param {file: File} Input file.
   * @return {bool} Is CSV or Plain?
   */
  static isParsable(file) {
    if (file) {
      const type = FileService.getFileType(file);
      return includes(type, SupportedTypes);
    } else {
      return false;
    }
  }
  static getFileType(file) {
    if (file) {
      if (file.type !== '') {
        return file.type;
      } else {
        const ext = FileService.getExtension(file.name);
        switch (ext.toLowerCase()) {
          case Extensions.TXT:
            return Types.PLAIN_TEXT;
          case Extensions.CSV:
            return Types.CSV;
          default:
            return '';
        }
      }
    } else {
      return '';
    }
  }
  static sanitizeStringForFilename(string) {
    return string.replace(/[^a-z0-9]/gi, '_');
  }

  // http://stackoverflow.com/a/12900504
  static getExtension(fileName) {
    if (fileName && typeof fileName === 'string') {
      return fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2); // eslint-disable-line no-bitwise
    } else {
      return '';
    }
  }
}
export default FileService;