/**
 * NOTE: This is not very nice for a reason ;)
 *
 */

import hmacSHA1 from "crypto-js/hmac-sha1";
import Base64 from "crypto-js/enc-base64";
class HumanService {
  static gen() {
    const svalue = this.gets();
    const rvalue = this.getu();
    const hvalue = Base64.stringify(hmacSHA1(rvalue, svalue));
    return `${rvalue}::${hvalue}`;
  }
  static gets() {
    const a = "yIHdas83xd2do9obDAS8FNX4l";
    const b = "BdsNANmdsaQ6321DWVbdaspC5eUo";
    return a + b + "sr3t1fMBH50"; // eslint-disable-line prefer-template
  }

  /* eslint-disable no-bitwise */
  static getu() {
    let uuid = "";
    let ii;
    for (ii = 0; ii < 32; ii += 1) {
      switch (ii) {
        case 8:
        case 20:
          uuid += "-";
          uuid += (Math.random() * 16 | 0).toString(16);
          break;
        case 12:
          uuid += "-";
          uuid += "4";
          break;
        case 16:
          uuid += "-";
          uuid += (Math.random() * 4 | 8).toString(16);
          break;
        default:
          uuid += (Math.random() * 16 | 0).toString(16);
      }
    }
    return uuid;
  }
  /* eslint-enable no-bitwise */
}
export default HumanService;