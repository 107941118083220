import * as React from 'react';
import { useEffect, useState } from 'react';
import isNil from 'ramda/es/isNil';
import { useDebouncedCallback } from 'use-debounce';

import { useOnEscapeBlur, useOnEscapeCallback } from '../hooks';

type DebouncedCallbackRef = {
  cancel: () => void;
  callPending: () => void;
};

type InputProps = {
  type: string;
  className: string;
  required?: boolean;
  placeholder?: string;
  onBlur?: React.FocusEventHandler;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  defaultValue?: string;
  maxLength?: number;
  minLength?: number;
  onKeyDown?: React.KeyboardEventHandler;
  onClear?: React.ChangeEventHandler;
  debounceMs?: number;
  inputRef: React.RefObject<HTMLInputElement>;
  useDebouncedCallbackRef?: React.MutableRefObject<DebouncedCallbackRef | null>;
};

const Input = ({
  debounceMs = 0,
  inputRef,
  onClear,
  useDebouncedCallbackRef,
  ...props
}: InputProps) => {
  const [value, setValue] = useState(props.value);
  const [debouncedFunction, cancel, callPending] = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange(e);
    },
    debounceMs,
  );

  useEffect(() => {
    if (!isNil(useDebouncedCallbackRef)) {
      useDebouncedCallbackRef.current = { cancel, callPending };
    }
  }, [cancel, callPending, useDebouncedCallbackRef]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useOnEscapeBlur(inputRef, !isNil(onClear));
  useOnEscapeCallback(inputRef, onClear, isNil(onClear));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setValue(e.target.value);
    debouncedFunction(e);
  };

  return (
    <input {...props} onChange={handleOnChange} value={value} ref={inputRef} />
  );
};

export default Input;
