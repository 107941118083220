export const DESKTOP = {
  id: 1,
  label: 'Desktop',
  iconName: 'laptop'
};
export const MOBILE = {
  id: 2,
  label: 'Mobile',
  iconName: 'mobile'
};
const PLATFORMS = [DESKTOP, MOBILE];
export default PLATFORMS;