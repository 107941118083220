import clipboardCopy from 'clipboard-copy';
import merge from 'ramda/src/merge';
const defaultMessages = {
  success: 'Successfully copied!',
  error: 'Sorry, something went wrong!',
  info: 'Nothing to copy'
};
class CopyService {
  static copy(value, customMessages) {
    return new Promise((resolve, reject) => {
      const messages = merge(defaultMessages, customMessages);
      clipboardCopy(value).then(() => resolve(messages.success)).catch(() => reject(messages.error));
    });
  }
}
export default CopyService;