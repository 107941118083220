import React, { useState, useRef, useEffect } from 'react';
import update from 'immutability-helper';
import { func, shape, string, objectOf } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { Input, withVisibilityLogic } from 'mangools-react-components/src';
import { trim, pipe, isNil } from 'ramda';
import UrlService from 'mangools-commons/lib/services/UrlService';
import { DESKTOP, MOBILE } from 'mangools-commons/lib/constants/Platforms';

import TrackingGroupService from 'services/TrackingGroupService';
import TrackingDomainService from 'services/TrackingDomainService';
import { newTrackingConfigDataSelector } from 'selectors/sharedSelectors';
import { resetNewTrackingConfigData, setNewTrackingConfigData } from 'actions/uiActions';
import TrackingGroupWithStatsType from 'types/TrackingGroupWithStatsType';
import MessageHolder from 'components/messages/MessageHolder';
import MyBusinessPlaceDropdown from 'components/other/MyBusinessPlaceDropdown';
import { trackingDetailLocationSelector } from 'selectors/dataSelectors';
import { usePrevious } from 'hooks/usePrevious';

const EditTrackingMessage = props => {
    const [domain, setDomain] = useState(props.tracking.domain);
    const domainInputRef = useRef(null);
    const newTrackingConfig = useSelector(newTrackingConfigDataSelector);
    const currentTrackingLocation = useSelector(trackingDetailLocationSelector);
    const prevGoogleBusinessId = usePrevious(newTrackingConfig?.place_id || null);
    const dispatch = useDispatch();

    const currentTrackingConfig = props.tracking?.trackingConfig;
    const formattedTrackingConfig =
        newTrackingConfig.place_id || newTrackingConfig?.forced_place_id ? newTrackingConfig : currentTrackingConfig;

    if (formattedTrackingConfig?.place_id && !formattedTrackingConfig.name) {
        formattedTrackingConfig.forced_place_id = formattedTrackingConfig.place_id;
        formattedTrackingConfig.place_id = null;
    }

    const trackingGroup =
        props.trackingGroups[
            TrackingGroupService.generateKey({
                domain: props.tracking.domain,
                locationId: props.tracking.location.id,
            })
        ];

    const hasOppositeTracking = !isNil(trackingGroup)
        ? !isNil(trackingGroup[MOBILE.label]) && !isNil(trackingGroup[DESKTOP.label])
        : null;

    useEffect(() => {
        if (formattedTrackingConfig) {
            dispatch(setNewTrackingConfigData(formattedTrackingConfig));
        }

        return () => {
            dispatch(resetNewTrackingConfigData());
        };
    }, [formattedTrackingConfig]);

    const handleChange = e => setDomain(trim(e.target.value));

    const handleBlur = () => {
        const sanitizedDomain = pipe(
            UrlService.getWithoutProtocol,
            UrlService.getWithoutWww,
            UrlService.getWithoutPath,
        )(domain);

        setDomain(sanitizedDomain);
    };

    const isBusinessNameChanged =
        (currentTrackingConfig?.place_id !== newTrackingConfig?.place_id &&
            (prevGoogleBusinessId || newTrackingConfig?.place_id !== null)) ||
        (currentTrackingConfig?.forced_place_id !== newTrackingConfig?.forced_place_id &&
            (prevGoogleBusinessId || newTrackingConfig?.place_id !== null));

    const handleSubmit = e => {
        e.preventDefault();

        props.onClose();
        const trackingConfig = isBusinessNameChanged ? newTrackingConfig : formattedTrackingConfig;
        if (trackingConfig.forced_place_id) {
            trackingConfig.place_id = trackingConfig.forced_place_id;
        }

        if (!trackingConfig?.place_id) {
            trackingConfig.place_id = null;
        }

        props.onSubmit(
            update(props.tracking, {
                $merge: {
                    domain,
                    trackingConfig,
                },
            }),
        );
    };

    const ctaDisabled = () =>
        TrackingDomainService.validate(domain) === false ||
        (domain === props.tracking.domain && !isBusinessNameChanged);

    const handleChangeForcedId = newForcedId => {
        if (formattedTrackingConfig?.place_id) {
            dispatch(resetNewTrackingConfigData());
        }

        dispatch(
            setNewTrackingConfigData({
                forced_place_id: newForcedId,
            }),
        );
    };

    const renderOppositeTrackingWarning = () => {
        if (hasOppositeTracking) {
            const platform = props.tracking.platformId === DESKTOP.id ? MOBILE.label : DESKTOP.label;

            return (
                <p className="mg-alert is-warning font-14">
                    <FontAwesomeIcon icon="info-circle" className="mg-margin-r-5" aria-hidden="true" />
                    The {platform.toLowerCase()} tracking has to be updated separately.
                </p>
            );
        } else {
            return null;
        }
    };

    return (
        <MessageHolder onClose={props.onClose}>
            <div className="mg-modal-header is-info">
                <FontAwesomeIcon icon="edit" aria-hidden="true" />
            </div>
            <div className="mg-modal-content">
                <h3 className="font-30 uk-text-bold">Change tracking details</h3>
                <p className="mg-margin-b-30">
                    <strong>After the domain change, you&apos;ll see the updated ranking data tomorrow.</strong>
                    &nbsp;All your historic ranking data will stay unchanged.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="uk-text-left">
                        <h3 className="font-montserrat font-14 color-grey mg-margin-b-10 uk-width-1-1">
                            New tracking domain
                        </h3>
                        <div className="mg-input-container is-url mg-margin-b-30">
                            <Input
                                autoFocus
                                className="mg-input"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                inputRef={domainInputRef}
                                placeholder="New tracking domain"
                                type="text"
                                value={domain}
                            />
                            <span className="mg-input-icon font-14 mg-padding-0-15" style={{ width: 'auto' }}>
                                <span className="color-grey" style={{ userSelect: 'none' }}>
                                    http(s)://
                                </span>
                            </span>
                        </div>
                        <div className="mg-input-container mg-margin-b-15">
                            <div className="uk-flex uk-flex-middle uk-flex-left">
                                <h3 className="font-montserrat font-14 color-grey mg-margin-b-10">
                                    New Google My Business listing name
                                </h3>
                                <span
                                    // eslint-disable-next-line max-len
                                    className="mg-label is-yellow is-small font-10 is-orange mg-margin-l-5 uk-position-relative uk-hidden-medium"
                                    style={{ top: '-5px' }}
                                >
                                    <strong>BETA</strong>
                                </span>
                            </div>
                            <MyBusinessPlaceDropdown
                                location={currentTrackingLocation}
                                gtmPayload={{ managing_location: 'edit', source: 'Manage GMB in SW' }}
                                currentTrackingConfig={formattedTrackingConfig}
                                handleChangeForcedId={handleChangeForcedId}
                                forcedId={formattedTrackingConfig?.forced_place_id || ''}
                            />
                        </div>
                        {renderOppositeTrackingWarning()}
                    </div>
                    <button className="mg-btn is-green" type="submit" disabled={ctaDisabled()}>
                        Edit tracking
                    </button>
                </form>
            </div>
        </MessageHolder>
    );
};

EditTrackingMessage.propTypes = {
    onClose: func.isRequired,
    tracking: shape({
        domain: string.isRequired,
        id: string.isRequired,
    }),
    onSubmit: func.isRequired,
    trackingGroups: objectOf(TrackingGroupWithStatsType).isRequired,
};

export default withVisibilityLogic(React.memo(EditTrackingMessage));
