import isNil from 'ramda/src/isNil';
import { KEY_CODES_WHITELIST, WHITELIST_DATA_ATTRIBUTE, IGNORE_TAGS } from '../constants/Shortcuts';
function ignoreEventsCondition(e) {
  if (!isNil(e.target) && IGNORE_TAGS.includes(e.target.tagName)) {
    if (KEY_CODES_WHITELIST.includes(e.code) && !isNil(e.target.getAttribute(WHITELIST_DATA_ATTRIBUTE))) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
export default {
  ignoreRepeatedEventsWhenKeyHeldDown: false,
  stopEventPropagationAfterIgnoring: false,
  ignoreEventsCondition
};