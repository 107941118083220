import any from 'ramda/src/any';
import equals from 'ramda/src/equals';
import curryN from 'ramda/src/curryN';
import concat from 'ramda/src/concat';
import isNil from 'ramda/src/isNil';
import Defaults from '../constants/Defaults';
import { MAX_ALEXA_RANK } from '../constants/Other';
class CSVExportService {
  static formatEmptyValue(value = undefined, functions = []) {
    const curriedEquals = curryN(2, equals)(value);
    const combinedFunctions = concat(functions, [isNil, () => curriedEquals(Defaults.NOT_AVAILABLE), () => curriedEquals(Defaults.NO_VALUE), () => curriedEquals(MAX_ALEXA_RANK)]);
    const isEmpty = any(fn => {
      return fn(value);
    }, combinedFunctions);
    if (isEmpty) {
      return '';
    } else {
      return value;
    }
  }
}
export default CSVExportService;