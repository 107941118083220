import includes from 'ramda/src/includes';
import isFQDN from 'validator/lib/isFQDN';
import isNil from 'ramda/src/isNil';
import isURL from 'validator/lib/isURL';
import pipe from 'ramda/src/pipe';
import split from 'ramda/src/split';
import type from 'ramda/src/type';
import isEmpty from 'ramda/src/isEmpty';
import tryCatch from 'ramda/src/tryCatch';
import Urls from '../constants/Urls';

// Full list here https://publicsuffix.org/list/public_suffix_list.dat
const MOST_COMMON_DOUBLE_TLDS_FIRST_PARTS = ['.co', '.com', '.edu', '.gov', '.mil', '.net', '.org', '.police'];
class UrlService {
  /*
   * NOTE: The most accurate solution would be to use https://www.npmjs.com/package/parse-domain
   * which includes the list of all correct TLD's and therefor is about 30KB gzipped in size
   * which is not worth the cost for us.
   *
   * So this is a poor man's version which occasionally fails to convert custom subdomain like
   * `lol.domain.com` into `domain.com`.
   */
  static getRoot(url) {
    if (isURL(url)) {
      let result = url;

      // Remove protocol
      result = result.replace(/^https?:\/\//, ''); // remove protocol

      // Remove www
      result = result.replace(/^www\./, ''); // remove WWW

      // Remove all after the /
      result = result.replace(/\/.*/, ''); // remove everything after the /

      // Count the number of dots
      const resultDotCount = result.split('.').length - 1;
      if (isFQDN(result) && resultDotCount === 1) {
        return result;
      } else {
        // Parse subdomain
        /* eslint-disable no-useless-escape */
        const regexParse = new RegExp('([A-zÀ-ÿ0-9]+([-.]{1,2}[A-zÀ-ÿ0-9]+)*).([A-zÀ-ÿ.]{2,5})$');
        /* eslint-enable no-useless-escape */
        const urlParts = regexParse.exec(result);
        if (isNil(urlParts)) {
          return url;
        } else if (urlParts[2] && resultDotCount === 2 && result.indexOf('-') === -1) {
          // URL includes subdomain or is double TLD (has two dots) and has no dashes
          if (includes(urlParts[2], MOST_COMMON_DOUBLE_TLDS_FIRST_PARTS)) {
            // Has double TLD like `co.uk`
            return `${urlParts[1]}.${urlParts[3]}`;
          } else {
            // Has custom subdomain
            return `${urlParts[2].replace(/\./, '')}.${urlParts[3]}`;
          }
        } else {
          return `${urlParts[1]}.${urlParts[3]}`;
        }
      }
    } else {
      return url;
    }
  }
  static getProtocol(url) {
    if (type(url) !== 'String') {
      return null;
    }
    return pipe(split('://'), ([protocol]) => protocol, protocol => isEmpty(protocol) ? null : protocol)(url);
  }
  static getWithoutProtocol(url) {
    return url.replace(/^https?:\/\//, '');
  }
  static getWithoutWww(url) {
    if (url.match(/^.*(:\/\/)?www./)) {
      return url.replace(/www\./, '');
    } else {
      return url;
    }
  }
  static getWithoutPath(url) {
    return url.replace(/\/.*$/, '');
  }
  static getWithoutProtocolAndWww(url) {
    if (isURL(url)) {
      let result = url;

      // Remove protocol
      result = UrlService.getWithoutProtocol(result);

      // Remove www
      result = UrlService.getWithoutWww(result);
      return result;
    } else {
      return url;
    }
  }
  static getUrlFromDomain(domain) {
    const protocol = 'http://';
    return `${protocol}${domain}`;
  }
  static getProxyUrl(url) {
    if (isURL(url)) {
      return `${Urls.MANGOOLS_PROXY_URL}/?url=${encodeURIComponent(url)}`;
    } else {
      return null;
    }
  }
  static getProxyImageUrl(url) {
    if (isURL(url)) {
      return `${Urls.MANGOOLS_PROXY_URL}/?image=${encodeURIComponent(url)}`;
    } else {
      return null;
    }
  }
  static getResourceName(url) {
    if (isURL(url)) {
      const lastShashIndex = url.lastIndexOf('/');
      return url.slice(lastShashIndex + 1, url.length);
    } else {
      return null;
    }
  }
  static getWithoutExtension(url) {
    if (url.match(/[aA-zZ0-9]\/.*\..*/g)) {
      return url.replace(/\.[^/.]+$/, '');
    } else {
      return url;
    }
  }
  static getWithoutTrailingSlash(url) {
    return url.replace(/\/$/, '');
  }
  static isPathUrl(url) {
    if (isURL(url)) {
      return url.match(/\*$/) !== null;
    } else {
      return false;
    }
  }
  static isImageUrl(url) {
    if (isURL(url)) {
      return url.match(/\.(jpg|jpeg|png|svg|gif|webp)$/) !== null;
    } else {
      return false;
    }
  }
  static getPath(url) {
    if (isURL(url)) {
      return pipe(tryCatch(data => new URL(data), (_err, data) => new URL(`https://${data}`)), data => {
        const {
          pathname,
          hash,
          search
        } = data;
        return `${pathname}${search}${hash}`;
      })(url);
    } else {
      return url;
    }
  }
  static format(url, options = {
    keepProtocol: false
  }) {
    if (isURL(url)) {
      const {
        keepProtocol
      } = options;
      return pipe(UrlService.getWithoutWww, UrlService.getWithoutExtension, UrlService.getWithoutTrailingSlash, data => {
        if (keepProtocol === false) {
          return UrlService.getWithoutProtocol(data);
        } else {
          return data;
        }
      }, tryCatch(data => {
        return decodeURI(data);
      }, (_err, data) => data))(url);
    } else {
      return url;
    }
  }
}
export default UrlService;