import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DESKTOP } from 'mangools-commons/lib/constants/Platforms';
import Colors from 'mangools-commons/lib/constants/Colors';
import classnames from 'classnames';
import { Favicon } from 'mangools-react-components/src';

import customizeReportPreviewImage from 'images/customize-report-preview.png';
import { isEmpty } from 'ramda';
import { CustomizeReportPanelPreviewProps } from 'components/panels/CustomizeReportPanel/CustomizeReportPanelPreview';

type CustomizeReportPanelReportPreviewProps = Omit<CustomizeReportPanelPreviewProps, 'email' | 'enabled' | 'protocol'>;

export const CustomizeReportPanelReportPreview: React.FC<CustomizeReportPanelReportPreviewProps> = ({
    logo,
    accentColor,
    name,
    website,
}) => {
    const hasLogo = !isEmpty(logo);

    return (
        <div>
            <div className="sw-customize-report-preview-tab">
                <div
                    // eslint-disable-next-line max-len
                    style={{
                        height: '80px',
                        fontSize: '10px',
                        maxWidth: '153px',
                        color: '#3e4348',
                        paddingTop: '26px',
                    }}
                    className="uk-flex"
                >
                    <span>
                        <Favicon className="mg-margin-r-10" domain={website || 'https://serpwatcher.com'} size={12} />
                    </span>
                    <span className="mg-truncate" title="example.com | SERPWatcher">
                        example.com | {!isEmpty(name) ? name : 'SERPWatcher'}
                    </span>
                </div>
            </div>
            <div className="uk-position-relative">
                <div
                    // eslint-disable-next-line max-len
                    className="uk-grid uk-grid-collapse uk-flex uk-flex-middle uk-position-absolute uk-width-1-1 mg-padding-15-0 mg-padding-0-30"
                    style={{
                        height: '105px',
                        background: '#fff',
                        boxShadow: '0 2px 8px 0 rgba(0,0,0,.07)',
                        borderTop: `3px solid ${!isEmpty(accentColor) ? accentColor : Colors.SERPWATCHER}`,
                        color: '#3e4348',
                    }}
                >
                    {hasLogo && (
                        <div className="sw-whitelabel-logo-wrapper mg-margin-r-15">
                            <img src={logo} alt="logo preview" />
                        </div>
                    )}
                    <div
                        className={classnames({
                            'sw-customize-report-preview-logo-border': hasLogo,
                            'mg-padding-l-15': hasLogo,
                        })}
                    >
                        <div className="font-montserrat font-24 font-small-18 mg-margin-b-5 mg-truncate">
                            <span className="mg-print-hidden">Report for&nbsp;</span>
                            <span className="uk-position-relative">
                                <strong>example.com</strong>
                            </span>
                        </div>
                        <div className="font-14 uk-flex uk-flex-middle">
                            <div className="mg-truncate uk-display-inline-block mg-margin-r-15">
                                <FontAwesomeIcon icon={DESKTOP.iconName} className="mg-margin-r-10" />
                                {DESKTOP.label}
                            </div>
                            <div className="mg-truncate uk-display-inline-block mg-margin-r-15">
                                <FontAwesomeIcon icon="map-marker-alt" className="mg-margin-r-10" />
                                United States
                            </div>
                            <div>
                                <strong>312</strong> tracked keywords
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    className="uk-dispaly-block"
                    style={{ marginTop: '105px' }}
                    alt="report preview"
                    src={customizeReportPreviewImage}
                />
            </div>
        </div>
    );
};
