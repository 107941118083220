import React, {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import isNil from 'ramda/src/isNil';

interface WindowSize {
  width: number | null;
  height: number | null;
}

const defaultWindowSize: WindowSize = {
  width: null,
  height: null,
};

const WindowContext = createContext<WindowSize>(defaultWindowSize);
const { Consumer, Provider } = WindowContext;

type WindowProviderProps = {
  children: ReactNode | ReactNode[];
};

const WindowProvider = ({ children }: WindowProviderProps) => {
  const [windowSize, setWindowSize] = useState<WindowSize>(defaultWindowSize);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleResize = () => {
    if (!isNil(timeoutRef.current)) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      timeoutRef.current = null;
    }, 150);
  };

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <Provider value={windowSize}>{children}</Provider>;
};

export { Consumer as WindowConsumer, WindowProvider, WindowContext };
