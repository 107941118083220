export const GTM_USER_DIMENSIONS = {
  user_login_status: 'user_login_status',
  user_created_at: 'user_created_at',
  user_plan: 'user_plan',
  user_id: 'user_id',
  user_created_at_i: 'user_created_at_i'
};
export const LOGIN_STATUS_TYPES = {
  loggedIn: 'logged',
  loggedOut: 'unlogged'
};