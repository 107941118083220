import isNil from 'ramda/src/isNil';
import fecha from 'fecha';
import Defaults from '../constants/Defaults';

/**
 * Utility class for DateTime management.
 */

export const JUST_NOW = 'Just now';
const BASIC_FORMAT = 'Do MMM YY';
class DateTimeService {
  /**
   * Return whether `referenceTime` is at least `minutesAgo` ago.
   *
   * @param {minutesAgo: number} Number of minutes ago.
   * @param {referenceTime: number} Reference time as a UNIX timestamp.
   * @return {bool} `referenceTime` is less than `minutesAgo` minute ago.
   */
  static lessThanMinutesAgo(minutesAgo, referenceTime) {
    const currentTime = Date.now();
    const minutesAgoMilis = minutesAgo * 60 * 1000;
    return currentTime - referenceTime < minutesAgoMilis;
  }

  /**
   * Get time difference between two UNIX timestamps in seconds.
   *
   * @param {from: number} UNIX timestamp from.
   * @return {number} Difference in seconds between `from` and now.
   */
  static getTimeDifferenceInSeconds(from) {
    const difference = Math.round((Date.now() - from) / 1000);
    return Math.abs(difference);
  }

  /**
   * Returns a short string representation of input year number.
   *
   * @param {year: number} i.e. 2016
   * @return {string} i.e. "'16"
   */
  static formatShortYear(year) {
    return `'${year.toString().substr(2, 2)}`;
  }
  static formatBasic(value) {
    if (isNil(value) || value === Defaults.NOT_AVAILABLE) {
      return Defaults.NOT_AVAILABLE;
    } else {
      return fecha.format(new Date(value), BASIC_FORMAT);
    }
  }
  static relativeAgoOrBasicYear(timestamp) {
    const date = new Date(timestamp);
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const minuteDiff = Math.floor(diff / 60);
    const hourDiff = Math.floor(diff / 3600);
    const dayDiff = Math.floor(diff / 86400);
    if (Number.isNaN(dayDiff)) {
      return Defaults.NOT_AVAILABLE;
    } else {
      return minuteDiff < 1 && JUST_NOW || minuteDiff < 60 && `${minuteDiff} ${minuteDiff === 1 ? 'minute' : 'minutes'} ago` || hourDiff < 24 && `${hourDiff} ${hourDiff === 1 ? 'hour' : 'hours'} ago` || dayDiff < 31 && `${dayDiff} ${dayDiff === 1 ? 'day' : 'days'} ago` || DateTimeService.formatBasic(timestamp);
    }
  }
}
export default DateTimeService;