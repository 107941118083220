class ShortcutService {
  static getKeymap({
    handlers,
    shortcuts,
    areShortcutsDisabled
  }) {
    return Object.entries(handlers).reduce((keymap, [shortcut, handler]) => {
      const {
        preventDisable = false,
        isDisabled = () => false
      } = handler;
      if (preventDisable === false && (isDisabled() === true || areShortcutsDisabled === true)) {
        return keymap;
      } else {
        // eslint-disable-next-line no-param-reassign
        keymap[shortcut] = shortcuts[shortcut];
        return keymap;
      }
    }, {});
  }
  static getHandlers({
    handlers,
    keyMap
  }) {
    return Object.keys(keyMap).reduce((handlersMap, shortcut) => {
      // eslint-disable-next-line no-param-reassign
      handlersMap[shortcut] = handlers[shortcut].func;
      return handlersMap;
    }, {});
  }
  static getShortcutMapAndHandlers({
    handlers,
    areShortcutsDisabled,
    shortcuts
  }) {
    const keyMap = ShortcutService.getKeymap({
      handlers,
      shortcuts,
      areShortcutsDisabled
    });
    const handlersMap = ShortcutService.getHandlers({
      handlers,
      keyMap
    });
    return {
      keyMap,
      handlers: handlersMap
    };
  }
}
export default ShortcutService;