import isNil from 'ramda/src/isNil';
import hasPath from 'ramda/src/hasPath';
class AuthService {
  constructor(query, tokens) {
    this.query = query;
    this.tokens = tokens;
  }
  hasEmptyQueryTokens() {
    return this.query.sso_ticket === null && this.query.login_token === null;
  }
  hasQueryTokens() {
    return !isNil(this.query.sso_ticket) && !isNil(this.query.login_token);
  }
  hasStoredTokens() {
    return !isNil(this.tokens.ssoTicket) && !isNil(this.tokens.loginToken);
  }
  hasInvalidStoredTokens(accessToken) {
    return !hasPath(['sso_ticket'], this.query) && !hasPath(['login_token'], this.query) && isNil(accessToken);
  }
}
export default AuthService;