import { useEffect } from 'react';

export const useScript = (text, condition = true) => {
    useEffect(() => {
        if (!condition) return;

        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.text = text;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [text, condition]);
};