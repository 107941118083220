/* eslint-disable no-underscore-dangle */
import queryString from 'query-string';
import { isNil, assoc } from 'ramda';
class LinkService {
  constructor() {
    this._source = undefined;
  }
  set source(source) {
    this._source = source;
  }
  getLink({
    ref,
    url,
    ...rest
  }) {
    let query = rest;
    if (!isNil(ref)) {
      query = assoc('ref', `${ref}-${this._source}`, query);
    }
    return queryString.stringifyUrl({
      url,
      query
    });
  }
}
export default new LinkService();