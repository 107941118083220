import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import Platforms from '../constants/Platforms';

/**
 * Service for finding platform from it's id.
 */
class PlatformFinderService {
  /**
   * Main findById function.
   *
   * @param {platformId: number} Id of platform.
   * @return {object} Platform object.
   */
  static findById(platformId) {
    return find(propEq('id', platformId))(Platforms);
  }
}
export default PlatformFinderService;