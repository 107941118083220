import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import assoc from 'ramda/src/assoc';
import queryString from 'query-string';
import TagManager from 'react-gtm-module';
import { GTM_USER_DIMENSIONS, LOGIN_STATUS_TYPES } from '../constants/Analytics';
class GTMService {
  static initialize(tagManagerArgs) {
    TagManager.initialize(tagManagerArgs);
    return new Promise((resolve, reject) => {
      TagManager.dataScript.onload = () => resolve('GTagManager initialized');
      TagManager.dataScript.onerror = e => reject(new Error(e));
    });
  }
  static get dataLayer() {
    return {
      push: data => {
        TagManager.dataLayer({
          dataLayer: data
        });
      }
    };
  }
  static setUserDimensions(user) {
    let userDimension = {};
    if (user[GTM_USER_DIMENSIONS.user_login_status] === true) {
      userDimension = assoc(GTM_USER_DIMENSIONS.user_login_status, LOGIN_STATUS_TYPES.loggedIn, userDimension);
    } else {
      userDimension = assoc(GTM_USER_DIMENSIONS.user_login_status, LOGIN_STATUS_TYPES.loggedOut, userDimension);
    }
    if (!isNil(user[GTM_USER_DIMENSIONS.user_created_at])) {
      const formattedDate = new Date(user[GTM_USER_DIMENSIONS.user_created_at]).toISOString().slice(0, 7).replace('-', '');
      userDimension = assoc(GTM_USER_DIMENSIONS.user_created_at, formattedDate, userDimension);
      userDimension = assoc(GTM_USER_DIMENSIONS.user_created_at_i, Date.parse(formattedDate), userDimension);
    }
    if (!isNil(user[GTM_USER_DIMENSIONS.user_plan])) {
      userDimension = assoc(GTM_USER_DIMENSIONS.user_plan, user[GTM_USER_DIMENSIONS.user_plan], userDimension);
    }
    if (!isNil(user[GTM_USER_DIMENSIONS.user_id])) {
      userDimension = assoc(GTM_USER_DIMENSIONS.user_id, user[GTM_USER_DIMENSIONS.user_id], userDimension);
    }
    userDimension = assoc('event', 'set_user_dimension', userDimension);
    GTMService.dataLayer.push(userDimension);
  }
  static pageView(path, query, data = {}) {
    const queryStr = queryString.stringify(query);
    GTMService.dataLayer.push({
      event: 'page_view',
      path: `${path}${!isEmpty(queryStr) ? `?${queryStr}` : ''}`,
      ...data
    });
  }
}
export default GTMService;