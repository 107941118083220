import { useEffect } from 'react';
import UsercomService from 'mangools-commons/lib/services/UsercomService';

import { analyticsActions } from '../constants/analytics';

enum Sources {
  KWFINDER = 'kwf',
  SERPWATCHER = 'sw',
  SERPCHECKER = 'sch',
  LINKMINER = 'lm',
  SITEPROFILER = 'sp',
  CONTENTGENERATOR = 'cg',
}

export const useTrackMessageVisibilityEffect = (
  event: string,
  gtmTrack: ({ action, event }: { action: string; event: string }) => void,
  attributes?: { source?: Sources },
) => {
  useEffect(() => {
    gtmTrack({ action: analyticsActions.SHOW, event });
    if (event === 'out_of_limits_message' && attributes) {
      UsercomService.createEvent({
        eventName: 'out_of_limits_message',
        attributes: { source: attributes.source },
      });
    }

    return () => gtmTrack({ action: analyticsActions.CLOSE, event });
  }, []);
};
